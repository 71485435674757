import { clsx } from '@digital-spiders/misc-utils';
import React, { useEffect, useRef, useState } from 'react';
import EdgeCut from './EdgeCut';
import * as styles from './EdgeCutWithDynamicWidth.module.scss';

type EdgeCutWithDynamicWidthProps = {
  children: React.ReactNode;
  sideHorizontal: 'left' | 'right';
  sideVertical: 'top' | 'bottom';
  proportion: number;
  className?: string;
  cutClassName?: string;
  hoverClassName?: string;
};

const EdgeCutWithDynamicWidth: React.FC<EdgeCutWithDynamicWidthProps> = ({
  children,
  sideHorizontal,
  sideVertical,
  proportion,
  className,
  cutClassName,
  hoverClassName,
}) => {
  const edgeCutContainerRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number | null>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (edgeCutContainerRef.current) {
        const parentWidth = edgeCutContainerRef.current.getBoundingClientRect().width;
        setWidth(parentWidth * proportion);
      }
    };

    updateWidth();
    const timeoutId = setTimeout(updateWidth, 0); // Ensure layout stabilizes

    const observer = new ResizeObserver(updateWidth);
    if (edgeCutContainerRef.current) {
      observer.observe(edgeCutContainerRef.current);
    }

    window.addEventListener('resize', updateWidth);
    return () => {
      clearTimeout(timeoutId);
      observer.disconnect();
      window.removeEventListener('resize', updateWidth);
    };
  }, [proportion]);

  return (
    <div ref={edgeCutContainerRef} className={clsx(styles.edgeCutContainer, className)}>
      {children}
      {width && (
        <EdgeCut
          sideVertical={sideVertical}
          sideHorizontal={sideHorizontal}
          widths={{ default: `${width}px` }}
          className={clsx(
            cutClassName,
            styles.edgeCutComponent,
            styles[sideHorizontal],
            styles[sideVertical],
          )}
          hoverClassName={hoverClassName}
        />
      )}
    </div>
  );
};

export default EdgeCutWithDynamicWidth;
